// TAKING STOCK

.col.taking-stock {
  overflow-y: scroll;
  background: $white;
  box-shadow: 2px 2px 2px 0 rgba($black, .2);
  box-sizing: border-box;

  .col__content {
    height: auto;
    padding-right: 0;
  }

  .col__title .h4 {
    font-size: 14px;
  }

  .col__cell:first-child {
    .h4 {
      span {
        border-bottom: 1px dotted $grayMd;
        cursor: context-menu;
      }

      .tooltip__box {
        width: 620px;

        .h4 {
          margin: 5px 0;
        }
      }
    }
  }

  .col__cell {
    width: 610px;
  }

  .col__range {
    margin: 0 0 2px;
    padding: 6px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: auto;

    background-color: $black;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    height: auto;

    background: $grayLt;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;

    background: $grayMd;
  }

  .taking-stock__section {
    border: 1px solid $orange;
    margin: 0 5px 10px;
    padding: 5px;
  }

  &_full {
    height: calc(100% - 56px);
    overflow: initial;

    .taking-stock__section {
      overflow: auto;
      overflow-x: hidden;
      max-height: 100%;
      height: 100%;

      &::-webkit-scrollbar {
        width: 6px;
        height: auto;
        background-color: #000;
      }

      &::-webkit-scrollbar-thumb {
          width: 6px;
          background: #b2bec3;
      }

      &::-webkit-scrollbar-track {
          width: 6px;
          height: auto;
          background: #f3f3f3;
      }
    }
  }
}

.taking-stock {
  &__section-title {
    margin-top: 10px;
    margin-left: 7px;
    margin-bottom: 20px;
  }

  &__question {
    color: #f58220;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0 6px 0 7px;

    &.numbered {
      padding-left: 1em;
      text-indent: -1em;
    }
  }

  &__counter {
    margin-right: 3px;
    &::after { content: "." }
  }

  &__inline-header {
    color: hsl(0, 0%, 25%);
    letter-spacing: .01em;
    font-size: .95em;
    margin-right: 3px;
    text-transform: uppercase;
  }

  &__radios {
    padding-left: 1em;
  }
}
